import React, { useState, useRef } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    content,
    image,
    ratio,
    typeName,
    name,
    button,
    modalContainer,
    modal,
    layerButton,
    closeButton,
    closeIcon,
    modalGrid,
    modalContent,
    details,
    modalName,
    ingredients,
    ingredientsLabel,
    caramelSplash01,
    caramelSplash02,
} from './recipe-card.module.scss';
import CloseIcon from '../../assets/images/svg/cross.svg';
import { ImageDataLike } from '../../models/image-data-like.model';
import { TWedelServingType } from './serving-card';

import Button from './button';
import RatioImage from '../../components/atoms/ratio-image';
import AbsoluteImage from './absolute-image';

export interface IWedelRecipe {
    name: string;
    type: TWedelServingType;
    image: ImageDataLike;
    ingredients: string[];
    preparation: string;
    garnish?: string;
    additionalLabel?: string;
    additionalContent?: string;
    caution?: string;
}

interface IRecipeCardProps {
    className?: string;
    recipe: IWedelRecipe;
}

const RecipeCard: React.FC<IRecipeCardProps> = ({ className, recipe }) => {
    const { t } = useI18next();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <div className={`${container} ${className}`}>
                <div className={content}>
                    <RatioImage
                        className={image}
                        image={getImage(recipe.image)}
                        ratioClass={ratio}
                    />
                    <p className={typeName}>{t(`wedel.recipe.type.${recipe.type}`)}</p>
                    <h3 className={name}>{recipe.name}</h3>
                </div>
                <Button className={button} onClick={handleShowModal}>
                    {t('wedel.recipe.card.see')}
                </Button>
            </div>
            {showModal && (
                <div className={modal} ref={modalRef}>
                    <button className={layerButton} onClick={handleCloseModal} tabIndex={-1} />
                    <div className={modalContainer}>
                        <AbsoluteImage image="caramel-splash-four" className={caramelSplash01} />
                        <AbsoluteImage image="caramel-splash-three" className={caramelSplash02} />
                        <Button className={closeButton} color="dark" onClick={handleCloseModal}>
                            <CloseIcon className={closeIcon} />
                        </Button>
                        <div className={modalGrid}>
                            <RatioImage
                                className={image}
                                image={getImage(recipe.image)}
                                ratioClass={ratio}
                            />
                            <div className={modalContent}>
                                <p className={modalName}>{recipe.name}</p>
                                <div className={details}>
                                    <p className={ingredientsLabel}>
                                        {t('wedel.recipe.card.ingredients')}:
                                    </p>
                                    <ul className={ingredients}>
                                        {recipe.ingredients.map((ingredient, index) => {
                                            return (
                                                <li key={`ingredient-${index}`}>{ingredient}</li>
                                            );
                                        })}
                                    </ul>
                                    <p>
                                        <strong>{t('wedel.recipe.card.preparation')}</strong>:{' '}
                                        {recipe.preparation}
                                    </p>
                                    {recipe.garnish && (
                                        <p>
                                            <strong>{t('wedel.recipe.card.decoration')}</strong>:{' '}
                                            {recipe.garnish}
                                        </p>
                                    )}
                                    {recipe.additionalLabel && recipe.additionalContent && (
                                        <p>
                                            <strong>{recipe.additionalLabel}</strong>:{' '}
                                            {recipe.additionalContent}
                                        </p>
                                    )}
                                    {recipe.caution && (
                                        <p>
                                            <strong>{recipe.caution}</strong>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RecipeCard;
