import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
    button,
    pillContainer,
    active,
    slider,
    pillInnerContainer,
    pillOuterContainer,
    pillSwitcher,
} from './pill-switcher.module.scss';

export interface IPillSwitcherOption {
    label: string;
    value: string | number;
}

export interface IPillSwitcherProps {
    options?: IPillSwitcherOption[];
    onChange?: (pill: IPillSwitcherOption) => void;
    activeOption?: IPillSwitcherOption;
    optionClassName?: string;
    className?: string;
}

interface IPillDimensions {
    value?: string;
    width: number;
    left: number;
}

const PillSwitcher = ({
    options = [],
    onChange,
    activeOption = options[0],
    optionClassName = '',
    className = '',
}: IPillSwitcherProps) => {
    const [activePill, setActivePill] = useState(activeOption || options[0]);
    const [pillsDimsArray, setPillsDimsArray] = useState<IPillDimensions[] | null>(null);

    const innerContainerRef = useRef<HTMLDivElement | null>(null);

    const calculatePillsDims = () => {
        if (innerContainerRef.current) {
            const pillsArr = Array.from(innerContainerRef.current.children) as HTMLDivElement[];
            setPillsDimsArray(
                pillsArr.map((pill) => ({
                    value: pill.dataset.value,
                    width: pill.clientWidth,
                    left: pill.offsetLeft,
                }))
            );
        }
    };

    const handlePillClick = (pill: IPillSwitcherOption) => () => {
        if (activeOption === null) {
            setActivePill(pill);
        }
        if (onChange && typeof onChange === 'function') {
            onChange(pill);
        }
    };

    useEffect(() => {
        if (activeOption !== null) {
            setActivePill(activeOption);
        }
    }, [activeOption]);

    useLayoutEffect(() => {
        setTimeout(() => {
            calculatePillsDims();
        }, 500); // setTimeout to ensure that pills dimensions will be calculated properly
        window.addEventListener('resize', calculatePillsDims);

        return () => window.removeEventListener('resize', calculatePillsDims);
    }, []);

    return (
        <div className={`${pillSwitcher} ${className}`}>
            <div className={pillOuterContainer}>
                <div className={pillContainer}>
                    <div className={pillInnerContainer} ref={innerContainerRef}>
                        {options.map((option, index) => {
                            return (
                                <button
                                    data-value={option.value}
                                    type="button"
                                    key={`pill-${index}`}
                                    className={`
                                        ${button} 
                                        ${getActiveOptionClassName({
                                            activeOption: activePill,
                                            option,
                                        })}
                                        ${optionClassName}
                                    `}
                                    onClick={handlePillClick(option)}
                                >
                                    {option.label}
                                </button>
                            );
                        })}
                    </div>
                    {Array.isArray(pillsDimsArray) && pillsDimsArray.length > 0 ? (
                        <div
                            className={slider}
                            style={{
                                width: `${
                                    pillsDimsArray[getPillIndex(activePill, options)].width
                                }px`,
                                left: `${pillsDimsArray[getPillIndex(activePill, options)].left}px`,
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

interface IGetActiveOptionClassNameProps {
    activeOption: IPillSwitcherOption;
    option: IPillSwitcherOption;
}

const getActiveOptionClassName = ({ activeOption, option }: IGetActiveOptionClassNameProps) => {
    if (activeOption.value === option.value) {
        return active;
    }
    return '';
};

function getPillIndex(pill: IPillSwitcherOption, options: IPillSwitcherOption[]) {
    return options.findIndex((option) => pill.value === option.value);
}

export default PillSwitcher;
